import React, { useEffect, useState } from 'react'
import Modal from "../../componentes/Modal";
import moment from "moment";
import { calcularTotales, CONTADO, decimalAdjust } from '../../Global';
import { notificarError, notificarMsg } from '../../componentes/Almacenes/AlmacenNotify';


const RANGE = {
    MINIMUM: 5,
    MAXIMUM: 150,
}

const DividirVale = (props) => {
    const [inProgress, setInProgress] = useState(false);
    const [counter, setCounter] = useState(-1);
    const [amounts, setAmounts] = useState([]);

    useEffect(() => {
        if (amounts.length === 0) {
            setCounter(-1);
            setInProgress(false);
            return;
        }

        if (counter < 0) {
            return;
        }

        if (counter >= amounts.length) {
            setCounter(-1);
            notificarMsg(`Los comprobantes han sido emitidos. Total de comprobantes: ${amounts.length}`)
            setInProgress(false);

            props.onCompleted();
            return;
        }

        sendOnce(amounts[counter]);
    }, [counter])

    const delay = (t) => new Promise(resolve => setTimeout(() => resolve(), t));

    const generateAmounts = (total) => {
        if (total <= RANGE.MINIMUM) {
            return [total]
        }

        const amounts = [];
        let sum = 0;

        while (true) {
            let num = Math.random() * (RANGE.MAXIMUM - RANGE.MINIMUM) + RANGE.MINIMUM;
            num = Math.round(num * 100) / 100;

            if (sum + num > total) {
                num = total - sum;
                if (num >= RANGE.MINIMUM && num <= RANGE.MAXIMUM) {
                    num = Math.round(num * 100) / 100;
                    amounts.push(num);
                    break;
                }
                continue;
            }

            amounts.push(num);
            sum += num;
            sum = Math.round(sum * 100) / 100;
        }

        return amounts;
    }

    const registrarVenta = async (monto, cantidad) => {
        const totales = calcularTotales([{
            ...props.detalle,
            Total: monto,
            Cantidad: cantidad,
        }]);

        const reqRegistrarVenta = await fetch(`/api/vales/convert-vales?idsVales=${props.idsVales}`, {
            method: "POST",
            body: JSON.stringify({
                IdCliente: props.IdCliente,
                IdTipoOperacion: `${props.detalle.IdVale} [${counter + 1 }]`,
                TipoTabla: "Vales",
                Estado: 'GENERANDO',
                IdModalidadPago: CONTADO,
                IdTipoComprobante: props.idTipoDoc,
                IdMoneda: 1,
                TipoCambio: 1,
                TasaIGV: 0.18,
                TasaICBPER: 0.3,
                DescuentoTotal: 0,
                Gravadas: totales.gravados,
                Exoneradas: totales.exonerados,
                Inafectas: totales.inafectos,
                ICBPER: totales.icbper,
                Exportacion: 0.0,
                Total: totales.total,
                Vuelto: 0,
                Observacion: `GENERADO DE: ${props.ventas.map(v => `${v.Serie}-${v.NumeroComprobante}-${v.aliasPlaca && v.aliasPlaca.length ? v.aliasPlaca : "Sin información"}\n`).join(' ')}`,
                IGV: totales.gravados * 0.18,
                ISC: 0,
                Gratuitas: totales.gratuitos,
                PorcentajeDescuento: 0,
                IVAP: 0,
                TasaIVAP: 0,
                TasaISC: 0,
                descuento: 0,
                Redondeo: totales.redondeo,
                alias: props.alias
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        });

        if (!reqRegistrarVenta.ok) {
            throw new Error("Ocurrió un error al registrar la venta");
        }

        const { IdRegistroVenta } = await reqRegistrarVenta.json();

        return IdRegistroVenta;
    }

    const agregarDetallesVenta = async (idRegistroVenta, cantidad, monto) => {
        const reqAgregarDetalles = await fetch(`/api/ventas/detalles/new?discountStock=1`, {
            method: "POST",
            body: JSON.stringify({
                Items: [[
                    idRegistroVenta,
                    props.detalle.IdStock,
                    cantidad,
                    props.detalle.PrecioReferencial,
                    props.detalle.PrecioVenta,
                    props.detalle.IdAfectacionIgv,
                    props.detalle.IdPresentacion,
                    monto,
                    props.detalle.Descuento,
                    props.detalle.TipoCambio,
                    props.detalle.ISC,
                    props.detalle.IGV,
                    props.detalle.ICBPER,
                    props.detalle.IVAP,
                    props.detalle.TasaISC,
                    props.detalle.ValorUnitario,
                    props.detalle.DescuentoSI,
                    props.detalle.Gratuito,
                ]],
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })

        if (!reqAgregarDetalles.ok) {
            throw new Error("Ocurrió un error al registrar los detalles de la venta");
        }
    }

    const registrarPagos = async (idRegistroVenta, monto) => {
        const reqAgregarPagos = await fetch(`/api/pagos/new`, {
            method: "POST",
            body: JSON.stringify({
                DetallesPagos: [[
                    idRegistroVenta,
                    props.idTipoPago,
                    Number(monto),
                    "PAGADO",
                    '',
                    moment(new Date()).format('YYYY-MM-DD'),
                    true,
                    0
                ]],
            }),
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
        })

        if (!reqAgregarPagos.ok) {
            throw new Error();
        }
    }

    const enviarSunat = async (idRegistroVenta) => {
        const reqEnvioSunat = await fetch(`/api/configFactElectronica/ce/${idRegistroVenta}`);
                
        if (!reqEnvioSunat.ok) {
            throw new Error("Ocurrió un error al registrar los pagos");
        }
    }

    const sendOnce = async (monto) => {
        let timeForDelay = 150;
        
        const cantidad = decimalAdjust('floor', monto * props.detalle.Cantidad / props.detalle.Total, -3);

        try {
            const idRegistroVenta = await registrarVenta(monto, cantidad);
            await agregarDetallesVenta(idRegistroVenta, cantidad, monto)
            await registrarPagos(idRegistroVenta, monto)
            await enviarSunat(idRegistroVenta)
        } catch (e) {
            notificarError(e.message);
            timeForDelay = 1_500;
        }
        
        await delay(timeForDelay);
        setCounter(counter => counter + 1);
    }

    const start = async () => {
        setAmounts([]);
        const montos = generateAmounts(props.detalle.Total);
        setAmounts(montos);

        setCounter(0);
        setInProgress(true);
    }

    return <>
        <button className="btn btn-correo-mfp" onClick={start}>Dividir</button>

        <Modal isOpen={inProgress} onClose={() => null} title={"En progreso"}>
            <div className="container my-3">
                <div className="col-12">
                    <span>Proceso en progreso, espere un momento...</span>
                    <div className="progress mt-3">
                        <div className="progress-bar progress-bar-striped" role="progressbar" style={{ width: `${parseInt((counter + 1) * 100 / amounts.length)}%` }} aria-valuemin="0" aria-valuemax="100"></div>
                    </div>
                </div>
            </div>
        </Modal>
    </>
}

export default DividirVale;
