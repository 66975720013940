import moment from "moment";
import {ESTADO_CE} from "../Global";

export function getConfExcelForDocReport(data) {
    return [
        {
            columns: [
                {
                    title: "FECHA",
                    width: {wpx: 90}
                },
                {
                    title: "FECHA DE PAGO",
                    width: {wpx: 90}
                },
                {
                    title: "TIPO",
                },
                {
                    title: "SERIE",
                    width: {wpx: 100}
                },
                {
                    title: "Nro. Comp",
                    width: {wpx: 100}
                },
                {
                    title: "NUMERO DE DOCUMENTO",
                    width: {wpx: 100}
                },
                {
                    title: "RAZON SOCIAL",
                    width: {wpx: 100}
                },
                {
                    title: "MODALIDAD",
                    width: {wpx: 100}
                },
                {
                    title: "ESTADO",
                    width: {wpx: 100}
                },
                {
                    title: "BASE",
                    width: {wpx: 100}
                },
                {
                    title: "BONIFICADA",
                    width: {wpx: 100}
                },
                {
                    title: "INAFECTAS"
                },
                {
                    title: "EXONERADAS"
                },
                {
                    title: "IGV"
                },
                {
                    title: "TOTAL"
                },
                {
                    title: "MONEDA",
                },
                {
                    title: "FECHA EMISIÓN"
                },
                {
                    title: "TIPO DOC"
                },
                {
                    title: "SERIE"
                },
                {
                    title: "NUM"
                },
                {
                    title: "DESC. CONFIGURACIÓN %"
                },
            ],

            data: data.map(row => {
                let tasaIGV = +row.TasaIGV
                let gravadas = row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : +row.gravadas;
                let inafectas = row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : +row.inafectas;
                let exoneradas = row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : +row.exoneradas;
                let igv = row.igv;

                const bonificadas = row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : +row.gratuitas;

                if (gravadas === 0 && inafectas === 0 && exoneradas == 0) {
                    gravadas = bonificadas / (1 + tasaIGV);
                    igv = gravadas * tasaIGV;
                } else if (bonificadas > 0 && row.GravaXBonificada > 0) {
                    igv += +row.GravaXBonificada * tasaIGV;
                }

                return [
                    {
                        value: moment(row.fechaEmision).format('DD-MM-YYYY'),
                    },
                    {
                        value: row.fechaPago ? moment(row.fechaPago).format('DD-MM-YYYY') : "",
                    },
                    {
                        value: `0${row.codigo}`,
                    },
                    {
                        value: row.serie || "",
                    },
                    {
                        value: row.numero || "",
                    },
                    {
                        value: row.numDocCliente || "",
                    },
                    {
                        value: row.razonSocial || "",
                    },
                    {
                        value: row.modalidad || "",
                    },
                    {
                        value: row.estadoCe || "",
                    },
                    {
                        value: gravadas,
                    },
                    {
                        value: row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : row.gratuitas,
                    },
                    {
                        value: row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : row.inafectas,
                    },
                    {
                        value: row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : row.exoneradas,
                    },
                    {
                        value: igv,
                    },
                    {
                        value: row.type === 'ventas' && row.estadoCe === ESTADO_CE.ANULADO ? 0 : row.total,
                    },

                    {
                        value: row.moneda || " ",
                    },
                    {
                        value: row.fechaDocAfectado ? moment(row.fechaDocAfectado).format("DD-MM-YYYY") : "",
                    },
                    {
                        value: row.codDocAfectado ? row.codDocAfectado : ""
                    },
                    {
                        value: row.serieDocAfectado ? row.serieDocAfectado : ""
                    },
                    {
                        value: row.numDocAfectado ? row.numDocAfectado : ""
                    },
                    {
                        value: row.discount || "0",
                    },

                ];
            })
        }
    ];
}

export function getConfExcelForMovReport(data) {
    return [
        {
            columns: [
                {
                    title: `MOVIMIENTOS DE ${data._prod.label} -`,
                    width: {wpx: 80}
                }
            ],
            data: []
        },
        {
            columns: [
                {
                    title: `DEL ${moment(data.fechaInicio).format("DD/MM/YYYY")} AL ${moment(data.fechaFin).format("DD/MM/YYYY")}`
                }
            ],
            data: []
        },
        {
            columns: [
                {
                    title: `FECHA EMISIÓN`,
                    width: {wpx: 100}
                },
                {
                    title: `PROVEEDOR`,
                    width: {wpx: 300}
                },
                {
                    title: `NUM SERIE`,
                    width: {wpx: 150}
                },
                {
                    title: `TIPO DOC`,
                    width: {wpx: 150}
                },
                {
                    title: `TIPO DE CAMBIO `,
                    width: {wpx: 80}
                },
                {
                    title: `CANTIDAD  `,
                    width: {wpx: 55}
                },
                {
                    title: `UNIDAD MEDIDA  `,
                    width: {wpx: 55}
                },
                {
                    title: `PRECIO`,
                    width: {wpx: 100}
                },
                {
                    title: `TOTAL`,
                    width: {wpx: 100}
                },
            ],
            data: data.movs.map(row => {
                return [
                    {
                        value: moment(row.FechaEmision).format("DD/MM/YYYY"),
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.RazonSocial || "Sin proveedor",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.NroDocumentoSunat || "Sin número",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.TipoDocumento || "Sin doc",
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.tipoCambio,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Cantidad,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.unidad,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Precio,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                    {
                        value: row.Total,
                        style: {
                            fill: {patternType: "solid", fgColor: {rgb: "F6F8FA"}}
                        }
                    },
                ];
            })
        }
    ]
}

export function getConfExcelForVentaReport(data) {
    return []
}
